import { ACCESS_TOKEN, LOGIN_USER_INFO } from "../../utils/globalConstants";
import JwtHelper from "../../utils/jwtHelper";
import { NSUserType } from "../../components/User/User.type";
import { RegisterDocument, SendOtpDocument } from "../../gql/graphql";
import { useGraphQLMutation } from "../../hooks/useGraphQL";

const useRegisterService = () => {
  const getUserInfo = (accessToken: string): NSUserType.IDbUser =>
    JwtHelper.getUserInfo(accessToken);
  const { mutateAsync: sendOTPMutationAsync } =
    useGraphQLMutation(SendOtpDocument);

  const { mutateAsync: registerMutationAsync } =
    useGraphQLMutation(RegisterDocument);

  const sendOtp = async (token: string, phoneNumber: string) => {
    const response = await sendOTPMutationAsync({
      invitationToken: token,
      phoneNumber,
    });

    return response;
  };
  const register = async (
    inviteToken: string,
    name: string,
    surname: string,
    otpCode: string,
    password: string,
    phoneNumber: string
  ) => {
    const response = await registerMutationAsync({
      inviteToken,
      name,
      surname,
      otpCode,
      password,
      phoneNumber,
    });
    if (response.register) {
      localStorage.setItem(ACCESS_TOKEN, response.register.accessToken);
      window.localStorage.setItem(
        LOGIN_USER_INFO,
        JSON.stringify(getUserInfo(response.register.accessToken))
      );
    }

    return response;
  };

  return {
    register,
    sendOtp,
  };
};

export default useRegisterService;
