import { SubmitHandler, useForm } from "react-hook-form";
import { boolean, object, ref, string } from "yup";
import { useCallback, useMemo, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import i18n from "../../../library/i18next";
import { NSRegisterFormType } from "./RegisterForm.type";
import { useParams } from "react-router-dom";
import useRegisterService from "../../../service/registerService/useRegisterService";
import toast from "react-hot-toast";
import ToastAlert from "../../../components/ToastAlert/ToastAlert";

const items: NSRegisterFormType.ITestableHint[] = [
  {
    label: i18n.t("register.passwordHint1"),
    achived: false,
    schema: string().min(8),
  },
  {
    label: i18n.t("register.passwordHint2"),
    achived: false,
    schema: string().matches(/[0-9]/),
  },
  {
    label: i18n.t("register.passwordHint3"),
    achived: false,
    schema: string().matches(/[a-zA-Z]/),
  },
  {
    label: i18n.t("register.passwordHint4"),
    achived: false,
    schema: string().matches(/[^a-zA-Z0-9]/),
  },
];

const registerFormSchema = object({
  phoneNumber: string().required(i18n.t("forms.phoneNumberError")),
  name: string().required(i18n.t("forms.nameError")),
  surname: string().required(i18n.t("forms.surnameError")),
  password: string()
    .required(i18n.t("forms.newPasswordError"))
    .min(8, i18n.t("forms.passwordMinLength"))
    .matches(
      /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/,
      i18n.t("settings.changePasswordWarning")
    ),
  confirmPassword: string()
    .required(i18n.t("forms.confirmNewPasswordError"))
    .oneOf([ref("password"), ""], i18n.t("forms.passwordMatchError")),
  acceptedTermsAndPrivacy: boolean()
    .required(i18n.t("forms.termsError"))
    .oneOf([true], i18n.t("forms.termsError")),
}).required();

const useRegisterFormVm = () => {
  const { register: registerService, sendOtp } = useRegisterService();
  const { token } = useParams();
  const [passwordHints, setPasswordHints] = useState(() => items);
  const [hasAcceptedTermsAndPrivacy, setHasAcceptedTermsAndPrivacy] =
    useState<boolean>(false);
  const [isOpenOTPModal, setIsOpenOTPModal] = useState<boolean>(false);
  const [formData, setFormData] =
    useState<NSRegisterFormType.IRegisterFormValues>();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<NSRegisterFormType.IRegisterFormValues>({
    resolver: yupResolver(registerFormSchema),
    shouldFocusError: false,
  });

  const registerHandler: SubmitHandler<NSRegisterFormType.IRegisterFormValues> =
    useCallback(async (data, _event) => {
      sendOtp(token as string, data.phoneNumber)
        .then(async () => {
          setFormData(data);
          setIsOpenOTPModal(true);
        })
        .catch((err) => {
          // TODO: add toast error
          toast(
            <ToastAlert
              description={i18n.t("forms.registerError")}
              type="error"
            />,
            {
              id: "registerError",
            }
          );

          console.log(err, "error");
        });
    }, []);

  const submitHandler = () => handleSubmit(registerHandler);

  const phoneCode = [
    {
      label: "+90",
      id: 90,
    },
    {
      label: "+56",
      id: 56,
    },
    {
      label: "+86",
      id: 86,
    },
    {
      label: "+45",
      id: 45,
    },
  ];

  const checkPasswordSecurity = (
    enteredPassword: string,
    hints: NSRegisterFormType.ITestableHint[]
  ): Promise<NSRegisterFormType.ITestableHint>[] => {
    const validationResult: Promise<NSRegisterFormType.ITestableHint>[] =
      hints.map(async (hint) => {
        const achived = await hint.schema.isValid(enteredPassword);
        const { label } = hint;
        const { schema } = hint;
        return Promise.resolve({ achived, label, schema });
      });

    return validationResult;
  };

  const onClickCloseOTPModal = () => {
    setIsOpenOTPModal(false);
  };

  const onClickVerifyOTP = (otp: string) => {
    registerService(
      token!,
      formData?.name!,
      formData?.surname!,
      otp,
      formData?.password!,
      formData?.phoneNumber!
    )
      .then(() => {
        window.location.href = "/projects";
      })
      .catch((err) => {
        toast(
          <ToastAlert description={i18n.t("forms.otpError")} type="error" />,
          {
            id: "otpError",
          }
        );
        setIsOpenOTPModal(false);
        console.log(err, "error");
      });
  };

  const passwordChangeHandler = useCallback(
    (enteredPassword: string) => {
      Promise.all(checkPasswordSecurity(enteredPassword, passwordHints)).then(
        (res) => {
          setPasswordHints(res);
        }
      );
    },

    [passwordHints]
  );

  const hintIems = useMemo(() => {
    const r = passwordHints.map((hint) => ({
      label: hint.label,
      achived: hint.achived,
    }));
    return r;
  }, [passwordHints]);

  return {
    formErrors: errors,
    control,
    phoneCode,
    hintIems,
    hasAcceptedTermsAndPrivacy,
    isOpenOTPModal,
    setValue,
    register,
    handleSubmit: submitHandler,
    passwordChangeHandler,
    setHasAcceptedTermsAndPrivacy,
    onClickCloseOTPModal,
    onClickVerifyOTP,
  };
};

export default useRegisterFormVm;
