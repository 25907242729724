import { Controller } from "react-hook-form";
import Logo from "../../../components/Logo/Logo";
import SolidButton from "../../../components/SolidButton/SolidButton";
import i18n from "../../../library/i18next";
import { NSRegisterFormStyle } from "./RegisterForm.style";
import useRegisterFormVm from "./RegisterFormVM";
import TextInput from "../../../components/TextInput/TextInput";
import PhoneNumber from "../../../components/PhoneNumber/PhoneNumber";
import PasswordInput from "../../../components/PasswordInput/PasswordInput";
import FormHint from "../../../components/FormHint/FormHint";
import Checkbox from "../../../components/Checkbox/CheckBox";
import { Trans } from "react-i18next";
import OTPModal from "../../../components/OTPModal/OTPModal";

function RegisterForm() {
  const registerFormVM = useRegisterFormVm();
  return (
    <>
      <NSRegisterFormStyle.Container>
        <Logo />
        <NSRegisterFormStyle.FormContainer>
          <NSRegisterFormStyle.Title>
            {i18n.t("register.title")}
          </NSRegisterFormStyle.Title>
          <NSRegisterFormStyle.Subtitle>
            {i18n.t("register.subtitle")}
          </NSRegisterFormStyle.Subtitle>
          <NSRegisterFormStyle.RegisterForm
            onSubmit={registerFormVM.handleSubmit?.()}
          >
            <Controller
              name="name"
              control={registerFormVM.control}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  id="name"
                  placeholder={i18n.t("forms.name")}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...field}
                />
              )}
            />

            <Controller
              name="surname"
              control={registerFormVM.control}
              render={({ field, fieldState: { error } }) => (
                <TextInput
                  id="surname"
                  placeholder={i18n.t("forms.surname")}
                  error={!!error?.message}
                  errorText={error?.message}
                  {...field}
                />
              )}
            />

            <NSRegisterFormStyle.PhoneContainer>
              <Controller
                name="phoneNumber"
                control={registerFormVM.control}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <PhoneNumber
                      id="phoneNumber"
                      dropdownItems={registerFormVM.phoneCode}
                      error={!!error?.message}
                      {...field}
                    />
                    {error?.message && (
                      <NSRegisterFormStyle.PhoneErrorText>
                        {error.message}
                      </NSRegisterFormStyle.PhoneErrorText>
                    )}
                  </>
                )}
              />

              <NSRegisterFormStyle.PhoneWarningText>
                {i18n.t("register.phoneWarning")}
              </NSRegisterFormStyle.PhoneWarningText>
            </NSRegisterFormStyle.PhoneContainer>

            <NSRegisterFormStyle.PasswordContainer>
              <NSRegisterFormStyle.PassWordTitle>
                {i18n.t("register.setPassword")}
              </NSRegisterFormStyle.PassWordTitle>
              <Controller
                name="password"
                control={registerFormVM.control}
                render={({ field, fieldState: { error } }) => {
                  const { onChange, ...fieldProps } = field;
                  return (
                    <NSRegisterFormStyle.PasswordInputContainer>
                      <PasswordInput
                        id="newPassword"
                        placeholder={i18n.t("forms.newPassword")}
                        onChange={(value) => {
                          field.onChange(value);
                          registerFormVM.passwordChangeHandler(value);
                        }}
                        error={!!error?.message}
                        {...fieldProps}
                      />
                      {error?.message && (
                        <NSRegisterFormStyle.ErrorText>
                          {error.message}
                        </NSRegisterFormStyle.ErrorText>
                      )}
                    </NSRegisterFormStyle.PasswordInputContainer>
                  );
                }}
              />
            </NSRegisterFormStyle.PasswordContainer>
            <FormHint hintItems={registerFormVM.hintIems} />
            <Controller
              name="confirmPassword"
              control={registerFormVM.control}
              render={({ field, fieldState: { error } }) => (
                <NSRegisterFormStyle.PasswordInputContainer>
                  <PasswordInput
                    id="confirmNewPassword"
                    placeholder={i18n.t("forms.confirmNewPassword")}
                    error={!!error?.message}
                    {...field}
                  />
                  {error?.message && (
                    <NSRegisterFormStyle.ErrorText>
                      {error.message}
                    </NSRegisterFormStyle.ErrorText>
                  )}
                </NSRegisterFormStyle.PasswordInputContainer>
              )}
            />

            <NSRegisterFormStyle.CheckboxContainer>
              <Controller
                name="acceptedTermsAndPrivacy"
                control={registerFormVM.control}
                render={({ field, fieldState: { error } }) => (
                  <NSRegisterFormStyle.CheckboxInputContainer>
                    <Checkbox
                      icon="CheckWhite"
                      checked={registerFormVM.hasAcceptedTermsAndPrivacy}
                      onClick={() => {
                        field.onChange(
                          !registerFormVM.hasAcceptedTermsAndPrivacy
                        );
                        registerFormVM.setHasAcceptedTermsAndPrivacy(
                          !registerFormVM.hasAcceptedTermsAndPrivacy
                        );
                      }}
                    />
                    {error?.message && (
                      <NSRegisterFormStyle.ErrorText>
                        {error.message}
                      </NSRegisterFormStyle.ErrorText>
                    )}
                  </NSRegisterFormStyle.CheckboxInputContainer>
                )}
              />

              <NSRegisterFormStyle.CheckboxText>
                <Trans
                  i18nKey="register.TermsAndPrivacy"
                  components={{
                    link1: (
                      <NSRegisterFormStyle.Link href="#" target="_blank" />
                    ),
                    link2: (
                      <NSRegisterFormStyle.Link href="#" target="_blank" />
                    ),
                  }}
                />
              </NSRegisterFormStyle.CheckboxText>
            </NSRegisterFormStyle.CheckboxContainer>

            <SolidButton
              label={i18n.t("register.getStarted")}
              type="submit"
              fullWidth
            />
          </NSRegisterFormStyle.RegisterForm>
        </NSRegisterFormStyle.FormContainer>
      </NSRegisterFormStyle.Container>
      {registerFormVM.isOpenOTPModal && (
        <OTPModal
          onClose={registerFormVM.onClickCloseOTPModal}
          onResend={() => {}}
          onVerify={registerFormVM.onClickVerifyOTP}
        />
      )}
    </>
  );
}

export default RegisterForm;
