import { useState } from "react";
import { ACCESS_TOKEN, LOGIN_USER_INFO } from "../../utils/globalConstants";

import { ActionResponse } from "../../types/ActionResponse";
import JwtHelper from "../../utils/jwtHelper";
import LocalStorageHelper from "../../utils/localStorageHelper";
import { NSAuthServiceType } from "./authService.type";
import { NSUserType } from "../../components/User/User.type";
import { LoginOutput } from "../../gql/graphql";
// eslint-disable-next-line import/no-extraneous-dependencies

const useAuthService = ({ dbAuth }: NSAuthServiceType.IUseAuth) => {
  const [isAuthLoading, setIsAuthLoading] = useState<boolean>(false);
  const [user, setUser] = useState<NSUserType.IAuth0User | undefined>();
  const [authError, setAuthError] = useState<string | null | undefined>(null);
  const getUserInfo = (accessToken: string): NSUserType.IDbUser =>
    JwtHelper.getUserInfo(accessToken);

  const removeSessionFromLocalStorage = () => {
    localStorage.removeItem(ACCESS_TOKEN);
  };

  const login = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<LoginOutput> => {
    try {
      removeSessionFromLocalStorage();
      setIsAuthLoading(true);
      const response = await dbAuth.login(email, password);
      if (response.accessToken) {
        setUser(getUserInfo(response.accessToken));
        localStorage.setItem(ACCESS_TOKEN, response.accessToken);
        window.localStorage.setItem(
          LOGIN_USER_INFO,
          JSON.stringify(getUserInfo(response.accessToken))
        );
      }
      setIsAuthLoading(false);
      return response;
    } catch (err) {
      setAuthError("Wrong user credentials");
      return null as any;
    }
  };

  const verify2FA = async (code: string) => {
    const response = await dbAuth.verify2FA(code);
    if (response.accessToken) {
      setUser(getUserInfo(response.accessToken));
      localStorage.setItem(ACCESS_TOKEN, response.accessToken);
      window.localStorage.setItem(
        LOGIN_USER_INFO,
        JSON.stringify(getUserInfo(response.accessToken))
      );
    }

    return response;
  };

  const logout = () => {
    setIsAuthLoading(true);
    removeSessionFromLocalStorage();
    setIsAuthLoading(false);
    window.location.pathname = "/auth";
    localStorage.removeItem(LOGIN_USER_INFO);
  };

  const isAuthenticated = () =>
    JwtHelper.isTokenValid(LocalStorageHelper.getAuthorizationToken());

  const forgotPassword = async (email: string) => {
    await dbAuth.forgotPassword(email);
    return true;
  };
  const resetPassword = async (
    email: string,
    token: string,
    password: string
  ) => {
    let response: ActionResponse | undefined;
    await dbAuth.resetPassword(email, token, password).then((res) => {
      response = res;
    });

    return response;
  };

  return {
    user,
    authError,
    login,
    logout,
    verify2FA,
    isAuthenticated,
    isAuthLoading,
    forgotPassword,
    resetPassword,
  };
};

export default useAuthService;
